// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { KeycloakConfig } from 'keycloak-angular';

const keycloakConfig = {
  url: 'https://keycloak.rbshost.net/auth',
  realm: 'RANCHOBIOSCIENCES',
  clientId: 'DASH-LOGIN',
};

export const environment = {
  production: false,
  envName: 'DEV',
  version: '2022.11.17',
  keycloak: keycloakConfig,
  // apiUrl: 'https://dev.chdidispatch.rbsapp.net/api',
  currentUser: {
    id: '',
    username: '',
    token: '',
    email: '',
    roles: [],
    isVendor: false,
    isAdmin: false,
  },
  sessionTimeout: 21600,

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
