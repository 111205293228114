import {Component, OnInit} from '@angular/core';
import OrgChart from '@balkangraph/orgchart.js';
import RBSdata from '../assets/data.json';
import {FormControl} from '@angular/forms';
// import {HttpClient, HttpClientModule, HttpEvent, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'RBSorgchart';
  selectedGroup = 'julie.bryant';
  employees: any[];
  groups: any[];
  chart: OrgChart;
  private baseUrl = 'http://localhost:4200';

  // constructor(public http: HttpClientModule) { }
  constructor() { }

  ngOnInit() {
    this.employees = RBSdata.employees;
    this.groups = RBSdata.groups;
    this.employees = this.groups.concat(this.employees);
    const tree = document.getElementById('tree');
    if (tree) {
      for (const node of this.employees) {
        if (!node.tags || node.tags.indexOf('group') < 0) {
          node.id = node.name.toLowerCase().replace(' ', '.');
          node.img = '../assets/photos/' + node.name + '.jpg';
        }
        switch (node.title) {
          case 'Contractor':
            node.tags = ['Contractor'];
            break;
        }
      }
      OrgChart.LINK_ROUNDED_CORNERS = 20;
      OrgChart.templates.isla.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{rounded}" />';
      OrgChart.templates.isla.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
        + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{children-count}</text>';
      // OrgChart.templates.isla.minus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
      //   + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{children-count}</text>';
      OrgChart.templates.isla.up =
        '<rect x="20" y="-25" width="30" height="17" fill="#aeaeae" rx="3" ry="3"></rect>'
        + '<line x1="35" x2="35" y1="0" y2="-8" stroke="#aeaeae" stroke-width="1"></line>';

      this.chart = new OrgChart(tree, {
        template: 'isla',
        mouseScrool: OrgChart.action.scroll,
        nodeBinding: {
          field_0: 'name',
          img_0: 'img',
          field_1: 'title',
        },
        roots: [this.selectedGroup],
        layout: OrgChart.tree,
        siblingSeparation: 10,
        subtreeSeparation: 20,
        mixedHierarchyNodesSeparation: 0,
        enableDragDrop: true,
        collapse: {
          level: 2,
          allChildren: true
        },
        align: OrgChart.ORIENTATION,
        toolbar: {
          layout: true,
          zoom: true,
          fit: true,
          expandAll: true
        },
        tags: {
          small: { template: 'small' },
          see_more: { template: 'see_more' },
          'node-with-subtrees': {
            template: 'group',
            subTreeConfig: {
              siblingSeparation: 3,
              columns: 2
            }
          },
          group: {
            template: 'group',
          },
          'devs-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'sales-group': {
            subTreeConfig: {
              columns: 1
            }
          },
          'admin-group': {
            subTreeConfig: {
              columns: 1
            }
          },
          'hr-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'curation-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'datamanagement-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'pm-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'dataengineering-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'bioinformatics-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'neurobiology-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'oncology-group': {
            subTreeConfig: {
              columns: 2
            }
          },
          'st-group': {
            subTreeConfig: {
              columns: 1
            }
          },
        },
        menu: {
          pdf: { text: 'Export PDF' },
          png: { text: 'Export PNG' },
          svg: { text: 'Export SVG' },
          csv: { text: 'Export CSV' },
          json: { text: 'Export JSON' }
        },
        editForm: {
          generateElementsFromFields: false,
          elements: [
            { type: 'textbox', label: 'Full Name', binding: 'name'},
            { type: 'textbox', label: 'Photo Url', binding: 'img', btn: 'Upload'},
            { type: 'textbox', label: 'Supervisor', binding: 'pid'},
            { type: 'select', options: [
                {value: 'bg', text: 'Bulgaria'},
                {value: 'ru', text: 'Russia'}],
              label: 'Group', binding: 'stpid' },
          ],
          buttons:  {
            edit: {
              icon: OrgChart.icon.edit(24, 24, '#fff'),
              text: 'Edit',
              hideIfEditMode: true,
              hideIfDetailsMode: false
            },
            share: null,
            pdf: {
              icon: OrgChart.icon.pdf(24, 24, '#fff'),
              text: 'Save as PDF'
            },
            remove: {
              icon: OrgChart.icon.remove(24, 24, '#fff'),
              text: 'Remove',
              hideIfDetailsMode: false
            }
          }
        }
      });

      this.chart.editUI.on('element-btn-click', (sender, args) => {
        OrgChart.fileUploadDialog(file => {
          const formData = new FormData();
          formData.append('file', file);

          // let progress = 0;
          // this.upload(file).subscribe(
          //   (event: any) => {
          //     if (event.type === HttpEventType.UploadProgress) {
          //       progress = Math.round(100 * event.loaded / event.total);
          //     } else if (event instanceof HttpResponse) {
          //       // this.message = event.body.message;
          //       // this.fileInfos = this.uploadService.getFiles();
          //     }
          //   },
          //   (err: any) => {
          //     console.log(err);
          //     progress = 0;
          //
          //     if (err.error && err.error.message) {
          //       // message = err.error.message;
          //     } else {
          //       // this.message = 'Could not upload the file!';
          //     }
          //
          //     // this.currentFile = undefined;
          //   });
        }, null);
      });

      this.chart.load(this.employees);
    }
  }

  selectRoot() {
    console.log(this.selectedGroup);
    this.chart.changeRoots(null, [this.selectedGroup]);
  }

  // upload(file: File): Observable<HttpEvent<any>> {
  //   const formData: FormData = new FormData();
  //
  //   formData.append('file', file);
  //
  //   const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
  //     reportProgress: true,
  //     responseType: 'json'
  //   });
  //
  //   return this.http.request(req);
  // }
}
