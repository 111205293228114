import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import Keycloak from 'keycloak-js';

// declare var keycloak: Keycloak;

@Injectable()
export class KeycloakService {

  constructor() { }

  private keycloakAuth: any;

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.keycloakAuth = Keycloak(environment.keycloak);
      this.keycloakAuth.init({
        onLoad: 'login-required',
        checkLoginIframe: false
        // enableBearerInterceptor: true,
      })
          .success(() => {
            resolve();
          })
          .error(() => {
            reject();
          });
    });
  }
  getToken(): string {
    return this.keycloakAuth.token;
  }

  getUsername(): string {
    return this.keycloakAuth.username;
  }

  getUserId(): string {
    return this.keycloakAuth.id;
  }
}

// @Injectable()
// export class TokenInterceptor implements HttpInterceptor {
//   constructor(private kcService: KeycloakService) {}
//
//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const authToken = this.kcService.getToken() || '';
//     request = request.clone({
//       setHeaders: {
//         'Authorization': 'Bearer ' + authToken,
//       },
//     });
//     return next.handle(request);
//   }
// }
